<template>
    <div class="pa-5 ma-3 white rounded-lg">
        <v-row>
            <v-col cols="8">
                <div class="subheading grey--text mb-9">User List</div>
            </v-col>
            <v-col cols="4" class="text-right">
                <v-btn depressed color="grey darken-4" class="white--text" to="/users/new">
                    <v-icon left>mdi-plus</v-icon>
                    New
                </v-btn>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="6" offset="6" class="pt-0">
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Filter" single-line hide-details></v-text-field>
            </v-col>
        </v-row>
        
        <v-data-table :headers="headers" :items="items" :footer-props="paginationOptions" :search="search"
            item-key="id" :options="pagination" :loading="loading" @click:row="toUserDetails">
            <template v-slot:item.lastLogin="{ item }">
                {{ formatDate( item.lastLogin, 'YYYY-MM-DD hh:mm a' ) }}
            </template>
            <template v-slot:item.active="{ item }">
                <v-chip x-small label :color="item.active ? 'success' : 'error'">{{ item.active ? 'Yes' : 'No' }}</v-chip>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-btn small icon @click.stop="openCodeDialog( item.id )">
                    <v-icon>mdi-lock-reset</v-icon>
                </v-btn>
            </template>
        </v-data-table>

        <v-dialog v-model="codeDialog" width="500" @input="manageDialog">
            <v-card>
                <v-card-title>Update code</v-card-title>
                <v-card-subtitle>Update the user code. This will be used to login to the rental app.</v-card-subtitle>
                <v-card-text>
                    <v-form ref="codeForm">
                        <v-text-field v-model="code" placeholder="code" outlined autofocus :rules="[rules.required, rules.minLength, rules.maxLength]"
                            @keydown.enter.prevent="updateCode"></v-text-field>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn block depressed color="grey darken-4" class="white--text" @click="updateCode" :loading="updatingCode">Update user code</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { API } from '@/inc/api';
import moment from 'moment';

export default {
    data: () => ({
        headers: [
            { text: 'ID', value: 'wintuxClerkNum', sortable: true },
            { text: 'Name', value: 'fullName', sortable: true },
            { text: 'Dept.', value: 'department', sortable: true },
            { text: 'Position', value: 'position', sortable: true },
            { text: 'Base store', value: 'baseStore', sortable: true },
            { text: 'Last login', value: 'lastLogin', sortable: true },
            { text: 'Active', value: 'active', sortable: true },
            { text: '', value: 'actions', sortable: false }
        ],
        pagination: {
            sortBy: ['wintuxClerkNum']
        },
        paginationOptions: { itemsPerPageOptions: [ 25, 50, -1 ] },
        loading: false,
        search: '',

        codeDialog: false,
        selectedId: null,
        code: null,
        updatingCode: false,
        rules: {
            required: v => !!v || 'required',
            minLength: v => v != null && v.length >= 4 || 'code must be at least 4 characters long',
            maxLength: v => v != null && v.length <= 15 || 'code maximum length is 15'
        }
    }),
    computed: {
        ...mapGetters({
            items    : 'users/users',
        })
    },
    mounted() {
        this.init();
    },
    methods: {
        ...mapActions({
           loadUsers    : 'users/loadUsers'
        }),
        async init() {

            try {
                this.loading = true;
                await this.loadUsers();
                this.loading = false;
            }
            catch(error) {
                this.loading = false;
                this.mxShowMessage( 'ERROR: ' + error, 'error' );
            }
        },
        toUserDetails( user ) {
            this.$router.push(`/users/${user.id}`);
        },
        openCodeDialog( userId ) {
            this.selectedId = userId;
            this.codeDialog = true;
        },
        manageDialog( val ) {
            if( val === true )
                return;

            this.codeDialog = false;
            this.code = null;
        },
        async updateCode() {
            if( !this.$refs.codeForm.validate() )
                return;

            let api = new API();

            try {
                this.updatingCode = true;
                let res = await api.put('/admin/user/security-code', { 
                    userId: this.selectedId,
                    code: this.code
                });
                this.updatingCode = false;

                if( !res.data.status ) {
                    this.mxShowMessage( res.data.message, res.data.error ? 'error' : 'warning' );
                    return;
                }

                this.mxShowMessage( 'Code changed', 'success' );
                this.codeDialog = false;
                this.code = null;
                this.$refs.codeForm.reset();
            }
            catch(error) {
                this.updatingCode = false;
                this.mxShowMessage( 'ERROR: ' + error, 'error' );
            }
        },
        formatDate( date, format ) {
            if( date == null ) return '';
            return moment( date ).format( format );
        }
    }
}
</script>

<style lang="scss" scoped>

</style>